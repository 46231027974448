#title-select {
    color: rgb(82, 95, 127);
    border: none;
    border-bottom: 2px solid rgb(82, 95, 127);
    cursor: pointer;
    outline:0px;
    scroll-behavior: smooth;
}

#title-select option {
    background-color: #f8f9fe;
    border: none;
}



#title-select:focus {
    outline:0;
}