.DashboardPeriod {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  padding-top: 12px;
  padding-bottom: 12px;

  margin-bottom: 24px;
}

.dateInputs {
  display: flex;
  gap: 15px;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.datePicker {
  width: 50%;
}