body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #525f7f;
  text-align: left;
  background-color: #f8f9fe;
}

.btn:hover {
  box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}
.btn {
  position: relative;
  text-transform: none;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
  letter-spacing: .025em;
  font-size: .875rem;
  will-change: transform;
  font-weight: 600;
}
.btn-primary, .btn-primary:hover {
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.card {
  flex: 1 1 auto;
  padding: 1.5rem;
  box-shadow: 0 0 2rem 0 rgba(136,152,170,.15)!important;
  border: none;
}

.apexcharts-toolbar {
  display: none!important;
} 
.apexcharts-tooltip {
  background: #FFF;
  border-radius: 10px!important;
  border: none!important;
}
.apexcharts-tooltip-title {
  background: #FFF!important;
  border: none!important;
  margin: 0!important;
}
.apexcharts-tooltip-series-group.active {
  background: #FFF;
  display: block;
  text-align: center!important;
}
.apexcharts-tooltip-series-group.active .apexcharts-tooltip-text {
  background: #FFF;
  text-align: center!important;
  font-size: 20px!important;
}
.apexcharts-xaxistooltip {
  display: none;
}
.apexcharts-tooltip-marker {
  display: none;
}
.card .table td, .card .table th {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.table .thead-light th {
  background-color: #f6f9fc;
  color: #8898aa;
}

.table.align-items-center td, .table.align-items-center th {
  vertical-align: middle;
}
.table .thead-light th {
  border-color: #e9ecef;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table thead th {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #e9ecef;
}
.dash-menu .nav-link {
  margin: 0 10px;
  font-size: 14px;
}
.gradient-primary {
  background: linear-gradient(87deg,#5e72e4,#825ee4)!important;
  color: #fff!important;
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: linear-gradient(87deg,#5e72e4,#825ee4)!important;
}

.form-search {
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  padding: 15px 25px;
  border-radius: 5px;
  outline: none;
}

.dropdown-menu {
  display: none;
}
.dropdown-menu.show {
  display: block;
}

.dropdown-menu.show {
padding: 0px;
box-shadow: unset;
border: 0px;
border-radius: 5px;
-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown-menu.show a {
min-width: 200px;
padding: 15px 16px;
clear: both;
line-height: 16px;
color: #333333;
font-size: 14px;
}
.dropdown-menu.show a:hover {
  background: rgba(0,0,0,0.04);
}
.text-primary {
  color: #825ee4!important;
}
.form-control:focus {
  box-shadow: none;
}

.apexcharts-tooltip-series-group.active {
  background: transparent;
  display: block;
  text-align: center!important;
}

.apexcharts-tooltip-series-group.active .apexcharts-tooltip-text {
  background: transparent!important;
  text-align: center!important;
  font-size: 20px!important;
}